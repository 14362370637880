import React from 'react'
import Layout from '../../components/layout/Layout'

const MerciPageContact = () => {
  return (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Merci !</h1>
          <p>Nous vous recontacterons sous peu !</p>
        </div>
      </div>
    </section>
  </Layout>
)}

export default MerciPageContact
